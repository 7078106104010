import React from 'react';
import styled, { css } from 'styled-components';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`;

const icon = css`
  cursor: pointer;
  color: black;
  transition: 0.2s;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  padding: 5px;

  &:hover {
    background-color: lightgray;
  }
`;

const LeftIcon = styled(AiOutlineLeft)`
  ${icon}
`;

const RightIcon = styled(AiOutlineRight)`
  ${icon}
`;

const PAGE_SIZE = 9;

type PaginationProps = {
  length: number;
  value: number;
  onChange: (arg0: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({ length, value, onChange }) => {
  const max = Math.ceil(length / PAGE_SIZE);
  const goPreviousPage = () => value > 1 && onChange(value - 1);
  const goNextPage = () => value < max && onChange(value + 1);

  return (
    <PaginationContainer>
      <div style={{ marginRight: '15px', width: '148px' }}>
        {(value - 1) * PAGE_SIZE + 1}-{Math.min((value - 1) * PAGE_SIZE + PAGE_SIZE, length)} of {length}
      </div>
      <LeftIcon size={20} cursor="pointer" onClick={goPreviousPage} />
      <RightIcon size={20} cursor="pointer" onClick={goNextPage} />
    </PaginationContainer>
  );
};

export default Pagination;
