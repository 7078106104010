import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Web3 from 'web3';

import * as mintABI from 'abi/mint.json';
import { Banner /*, Countdown */ } from './components';
import { CONTRACT_ADDRESS, MINT_PRICE } from 'utils/addresses';
import { Row } from 'components';

// eslint-disable-next-line
declare let window: any;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  @media screen and (max-width: 740px) {
    flex-direction: column;
  }
`;

const Panel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(50% - 80px);

  @media screen and (max-width: 740px) {
    width: auto;
  }
`;

const MintButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  height: 40px;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
  transition: 0.1s;

  :active {
    transform: scale(0.98);
  }
`;

const ChangeMintButton = styled.button`
  height: 100%;
  width: 30px;
  font-size: 20px;
`;

const MintValueContainer = styled.div`
  width: 110px;
  display: grid;
  place-items: center;
  font-size: 20px;
`;

const MintButton = styled.button`
  padding: 9.5px 20px;
  font-size: 18px;
  border-radius: 5px;
  margin-left: 10px;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
  transition: 0.1s;

  :active {
    transform: scale(0.98);
  }
`;

type Props = {
  rightChainID: boolean;
};

const Mint: React.FC<Props> = ({ rightChainID }) => {
  const [huskyMinted, setHuskyMinted] = useState<null | number>(null);
  const [mintValue, setMintValue] = useState(1);

  useEffect(() => {
    (async () => {
      if (huskyMinted !== null) return;
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);

        // TODO: Loading
        // eslint-disable-next-line
        const claimContract = new window.web3.eth.Contract(mintABI.abi as any, CONTRACT_ADDRESS);

        try {
          const totalSupply = await claimContract.methods.totalSupply().call();
          setHuskyMinted(totalSupply);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  });

  const decrementMintValue = () => {
    if (mintValue > 1) setMintValue(mintValue - 1);
  };

  const incrementMintValue = () => {
    if (mintValue < 20 && mintValue < 5000 - (huskyMinted || 0)) setMintValue(mintValue + 1);
  };

  const mint = async () => {
    // TODO: Get AVAX Balance and set error message if not enough
    if (!window.web3 || !rightChainID) return;

    // TODO: Loading
    // eslint-disable-next-line
    const claimContract = new window.web3.eth.Contract(mintABI.abi as any, CONTRACT_ADDRESS);

    try {
      const accounts = await window.web3.eth.getAccounts();
      await claimContract.methods
        .mintHusky(mintValue)
        .send({ from: accounts[0], value: window.web3.utils.toWei(`${mintValue * MINT_PRICE}`, 'ether') });
    } catch (error) {
      console.log(error);
    }

    // TODO: Undo loading
  };

  return (
    <Container>
      <Banner />
      <Panel>
        <div>
          {/* <Countdown /> */}
          <h1>Mint your husky !</h1>
          <p style={{ fontSize: '18px', marginRight: '5px' }}>Husky minted: {huskyMinted || 0} / 5 000</p>
          <Row style={{ marginTop: '15px', alignItems: 'center' }}>
            <MintButtonContainer>
              <ChangeMintButton onClick={decrementMintValue}>-</ChangeMintButton>
              <MintValueContainer>{mintValue}</MintValueContainer>
              <ChangeMintButton onClick={incrementMintValue}>+</ChangeMintButton>
            </MintButtonContainer>
            <MintButton onClick={() => mint()}>MINT</MintButton>
          </Row>
          <Row style={{ marginTop: '15px', fontSize: '18px' }}>
            <div style={{ width: '40px' }}>{MINT_PRICE * mintValue}</div>
            <div>AVAX</div>
          </Row>
          {/* <button onClick={() => start()}>start</button> */}
        </div>
      </Panel>
    </Container>
  );
};

export default Mint;
