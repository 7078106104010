import React, { useState } from 'react';
import styled from 'styled-components';

const Image = styled.img`
  display: ${({ loaded }: { loaded: boolean }) => (loaded ? 'inline-block' : 'none')};
  background-color: ${({ loaded }: { loaded: boolean }) => (loaded ? 'none' : 'red')};
  width: 100%;
`;

const SpinningLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1.74834437086;
`;

const SpinningLoader = styled.div`
  height: 52px;
  width: 52px;
  display: inline-block;
  border: 4px solid black;
  border-radius: 50%;
  border-top-color: white;
  animation: 1s spin infinite ease-in-out;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

type ImageSkeletonProps = {
  src: string;
};

const ImageSkeleton: React.FC<ImageSkeletonProps> = ({ src }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && (
        <SpinningLoaderContainer>
          <SpinningLoader />
        </SpinningLoaderContainer>
      )}
      <Image src={src} loaded={loaded} onLoad={() => setLoaded(true)} />
    </>
  );
};

export default ImageSkeleton;
