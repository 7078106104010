import React from 'react';
import styled from 'styled-components';

import husky from 'assets/husky-nfts/BROKENICEHUSK.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 80px - 50px);
`;

const SubContainer = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-top: 20px;

  @media screen and (max-width: 780px) {
    grid-template-columns: 1fr;
    padding-bottom: 40px;
  }
`;

const LeftImage = styled.img`
  width: 100%;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
`;

const About: React.FC = () => {
  return (
    <Container>
      <h1 style={{ margin: '15px 0' }}>About us</h1>
      <SubContainer>
        <LeftImage src={husky} />
        <p style={{ border: '5px solid black', padding: '15px', lineHeight: '1.6', width: '100%' }}>
          Husky 3D is the first NFT collection made by{' '}
          <a href="https://twitter.com/LS30sounds" style={{ color: 'black', fontWeight: 700 }}>
            Husky Avax artist L$30{' '}
          </a>
          The collection of 5,000 NFTs will be comprised of 100 different 3D huskies featuring different textures and
          skins, all with varying levels of rarity, including two completely unique designs.
          <br />
          <br />
          Holders of the Huksy Avax token will directly benefit from the sale of the Husky 3D NFT! 30% of the initial
          NFT sale will be used to buy back Husky Avax tokens for either a token burn or to provide locked liquidity.
          The Husky community will decide this via poll at a later date. 10% of the initial sale will be used for
          marketing Husky Avax and for CEX listings. 5% of each transaction on the Husky NFT marketplace will be
          reflected to holders of the Husky 3D NFTs.
          <br />
          <br />
          You want to know more about husky project?{' '}
          <a style={{ color: 'black', fontWeight: 700 }} href="https://husky.space">
            Click here
          </a>
        </p>
      </SubContainer>
    </Container>
  );
};

export default About;
