import React, { useState } from 'react';
import styled from 'styled-components';

import * as mintABI from 'abi/mint.json';
import { CONTRACT_ADDRESS } from 'utils/addresses';

// eslint-disable-next-line
declare let window: any;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 80px - 50px);
`;

const Admin: React.FC = () => {
  const [withdrawValue, setWithdrawValue] = useState(0);
  const [baseUri, seetBaseUri] = useState('');

  const start = async () => {
    if (!window.web3) return;

    // eslint-disable-next-line
    const claimContract = new window.web3.eth.Contract(mintABI.abi as any, CONTRACT_ADDRESS);

    try {
      const accounts = await window.web3.eth.getAccounts();
      await claimContract.methods.startDrop().send({ from: accounts[0] });
    } catch (error) {
      console.log(error);
    }
  };

  const withdraw = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!window.web3) return;

    // eslint-disable-next-line
    const claimContract = new window.web3.eth.Contract(mintABI.abi as any, CONTRACT_ADDRESS);

    try {
      const accounts = await window.web3.eth.getAccounts();
      await claimContract.methods.withdrawAll().send({ from: accounts[0] });
    } catch (error) {
      console.log(error);
    }
  };

  const setBaseUri = async () => {
    if (!window.web3) return;

    // eslint-disable-next-line
    const claimContract = new window.web3.eth.Contract(mintABI.abi as any, CONTRACT_ADDRESS);

    try {
      const accounts = await window.web3.eth.getAccounts();
      await claimContract.methods.setBaseURI(baseUri).send({ from: accounts[0] });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <h1>Enculé</h1>
      <button style={{ padding: '15px' }} onClick={start}>
        Start the minting
      </button>

      <form style={{ marginTop: '30px' }} onSubmit={withdraw}>
        <input
          type="number"
          value={withdrawValue}
          disabled
          onChange={(event) => setWithdrawValue(parseInt(event.target.value))}
        />
        AVAX
        <button style={{ marginLeft: '15px', padding: '15px' }} type="submit">
          WithdrawAll
        </button>
      </form>

      <div style={{ display: 'flex', marginTop: '20px' }}>
        <input type="text" value={baseUri} onChange={(event) => seetBaseUri(event.target.value)} />
        <button onClick={setBaseUri} style={{ marginLeft: '15px', padding: '15px' }} type="submit">
          setBaseUri
        </button>
      </div>
    </Container>
  );
};

export default Admin;
