import React, { useState } from 'react';
import styled from 'styled-components';

import * as marketplaceABI from 'abi/marketplace.json';
import { Modal } from 'components';
import { MARKETPLACE_CONTRACT_ADDRESS } from 'utils/addresses';

// eslint-disable-next-line
declare let window: any;

const ItemTitle = styled.h2`
  padding: 0 5px;
  margin: 15px;
`;

const ItemImage = styled.img`
  width: 100%;
`;

const ModalBody = styled.div`
  padding: 15px;
`;

const BuyButton = styled.button`
  padding: 8px 5px;
  margin-top: 15px;
  border-radius: 6px;
  font-size: 18px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
`;

type ItemModalProps = {
  trigger: JSX.Element;
  item: {
    id: string;
    image: string;
    rarity: number;
    background: string;
    type: string;
    price: number;
  };
};

const ItemModal: React.FC<ItemModalProps> = ({ item, trigger }) => {
  const [show, setShow] = useState(false);

  const onOpen = () => setShow(true);

  const onClose = () => setShow(false);

  const buy = async () => {
    if (!window.web3) return;

    // TODO: Loading
    // eslint-disable-next-line
    const marketplaceContract = new window.web3.eth.Contract(marketplaceABI.abi as any, MARKETPLACE_CONTRACT_ADDRESS);

    try {
      const accounts = await window.web3.eth.getAccounts();
      console.log(accounts[0]);
      await marketplaceContract.methods
        .buyToken(parseInt(item.id))
        .send({ from: accounts[0], value: window.web3.utils.toWei(`${item.price}`, 'ether') });
      onClose();
    } catch (error) {
      console.log(error);
    }
    // TODO: Undo loading
  };

  return (
    <Modal onClose={onClose} onOpen={onOpen} show={show} trigger={trigger}>
      <ItemTitle>Husky #{item.id}</ItemTitle>
      <ItemImage src={item.image} />
      <ModalBody>
        <span style={{ fontWeight: 700 }}>Rarity</span>:{' '}
        <span style={{ textTransform: 'capitalize' }}>{item.rarity}</span> / 27
        <br />
        <span style={{ fontWeight: 700 }}>Background</span>:{' '}
        <span style={{ textTransform: 'capitalize' }}> {item.background}</span>
        <br />
        <span style={{ fontWeight: 700 }}>Type</span>: <span style={{ textTransform: 'capitalize' }}>{item.type}</span>
        <br />
        <span style={{ fontWeight: 700 }}>Price</span>:{' '}
        <span style={{ textTransform: 'capitalize' }}>{item.price}</span> AVAX
        <BuyButton onClick={buy}>Buy</BuyButton>
      </ModalBody>
    </Modal>
  );
};

export default ItemModal;
