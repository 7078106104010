import React, { useEffect } from 'react';
import Web3 from 'web3';
import styled from 'styled-components';

import { CHAIN_ID, CHAIN_ID_STRING, CHAIN_NAME, RPC_URL } from 'utils/addresses';

// eslint-disable-next-line
declare let window: any;

const Button = styled.button`
  position: fixed;
  top: 95px;
  right: 15px;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 17px;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
  z-index: 20;
`;

type ConnectButtonProps = {
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  connected: boolean;
  setConnected: React.Dispatch<React.SetStateAction<boolean>>;
  rightChainID: boolean;
  setRightChainID: React.Dispatch<React.SetStateAction<boolean>>;
};

const connectWallet = async (): Promise<boolean> => {
  console.log('Connecting wallet...');

  if (window.ethereum) {
    try {
      await window.ethereum.send('eth_requestAccounts');
      return true;
    } catch {
      console.log('Website connection rejected');
      return false;
    }
  } else {
    console.log('No web3 wallet');
    return false;
  }
};

const ConnectButton: React.FC<ConnectButtonProps> = ({
  address,
  setAddress,
  connected,
  setConnected,
  rightChainID,
  setRightChainID,
}) => {
  const connect = async () => {
    if (connected) return;
    const isConnected = await connectWallet();
    if (isConnected) {
      setConnected(true);
      const _address = await window.web3.eth.getAccounts();
      setAddress(_address[0]);
      const _chainID = await window.web3.eth.getChainId();
      setRightChainID(_chainID === CHAIN_ID);
    }
  };
  const onButtonClick = async () => {
    if (connected) {
      if (!rightChainID) {
        const data = [
          {
            chainId: CHAIN_ID_STRING,
            chainName: CHAIN_NAME,
            nativeCurrency: {
              name: 'AVAX',
              symbol: 'AVAX',
              decimals: 18,
            },
            rpcUrls: [RPC_URL],
          },
        ];

        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: data,
          });
        } catch (exception) {
          console.error(exception);
        }
        return;
      }
    }
    connect();
  };

  useEffect(() => {
    (async () => {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        connect();

        // LISTENERS
        window.ethereum.on('chainChanged', (chainId: string) => {
          setRightChainID(parseInt(chainId, 16) === CHAIN_ID);
          console.log(`New chain: ${chainId}`);
        });
        window.ethereum.on('accountsChanged', (accounts: Array<string>) => {
          if (!accounts[0]) {
            setConnected(false);
            return;
          }

          setAddress(accounts[0]);
          console.log(`New accounts: ${accounts}`);
        });
      } else console.log('No web3 wallet');
    })();
  }, []);

  return (
    <Button onClick={onButtonClick}>
      {connected
        ? rightChainID
          ? `${address.slice(0, 6)}...${address.slice(address.length - 4, address.length)}`
          : 'Switch to Avalanche'
        : 'Connect'}
    </Button>
  );
};

export default ConnectButton;
