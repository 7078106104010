import React, { useState } from 'react';
import styled from 'styled-components';
import { IoMdArrowDropdown } from 'react-icons/io';

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
  padding: 8px;
  min-width: 145px;

  @media screen and (max-width: 1000px) {
    margin-top: 10px;
  }
`;

const DropdownBody = styled.div`
  position: absolute;
  top: 44px;
  left: 0;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
  z-index: 20;
  overflow: hidden;
`;

const DropdownIcon = styled(IoMdArrowDropdown)`
  transition: 0.2s;
  transform: ${(props: { opened: boolean }) => (props.opened ? 'rotate(180deg)' : 'rotate(0)')};
`;

const DropdownItem = styled.div`
  padding: 5px;
  transition: 0.1s;
  text-transform: capitalize;

  &:hover {
    background-color: black;
    color: white;
  }
`;

const ClearButton = styled.button`
  width: calc(100% - 10px);
  margin: 5px;
  border-radius: 2px;
  padding: 5px;
  font-size: 16px;
`;

type DropdownProps = {
  ascending?: boolean;
  items: Array<string>;
  title: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  setAscending?: (arg0: boolean) => void;
  value: string;
  style?: Record<string, string>;
};

const FilterDropdown: React.FC<DropdownProps> = ({ style, title, items, onChange, value }) => {
  const [open, setOpen] = useState(false);
  return (
    <DropdownContainer style={style} onClick={() => setOpen((prevOpen) => !prevOpen)}>
      {title}: <div style={{ textTransform: 'capitalize', width: '60px', marginLeft: '6px' }}>{value}</div>
      <DropdownIcon opened={open} size={20} />
      {open && (
        <DropdownBody>
          {items.map((item) => {
            return (
              <DropdownItem
                style={{ fontWeight: value === item ? 700 : 400 }}
                onClick={() => onChange(item)}
                key={item}
              >
                {item}
              </DropdownItem>
            );
          })}
          <ClearButton onClick={() => onChange('')}>Clear</ClearButton>
        </DropdownBody>
      )}
    </DropdownContainer>
  );
};

export default FilterDropdown;
