import React, { useState } from 'react';
import styled from 'styled-components';

import * as mintABI from 'abi/mint.json';
import * as marketplaceABI from 'abi/marketplace.json';
import { Column, Modal } from 'components';
import { CONTRACT_ADDRESS, MARKETPLACE_CONTRACT_ADDRESS } from 'utils/addresses';

// eslint-disable-next-line
declare let window: any;

const ItemTitle = styled.h2`
  padding: 0 5px;
  margin: 15px;
`;

const ItemImage = styled.img`
  width: 100%;
`;

const ModalBody = styled.div`
  padding: 15px;
`;

const SellButton = styled.button`
  padding: 8px 5px;
  margin-top: 15px;
  border-radius: 6px;
  font-size: 18px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
`;

const SellInput = styled.input`
  padding: 7px;
  margin-top: 5px;
  margin-right: 5px;
  outline: none;
  border: 1px solid black;
  border-radius: 5px;
`;

type ItemModalProps = {
  trigger: JSX.Element;
  item: {
    id: string;
    image: string;
    rarity: number;
    background: string;
    type: string;
    price: number;
  };
  isApproved: boolean;
  setIsApproved: (approved: boolean) => void;
};

const ItemModal: React.FC<ItemModalProps> = ({ setIsApproved, isApproved, item, trigger }) => {
  const [step, setStep] = useState<0 | 1>(0);
  const [show, setShow] = useState(false);
  const [price, setPrice] = useState<string>(item.price ? `${item.price}` : '');

  const onOpen = () => setShow(true);

  const onClose = () => [setShow(false), setStep(0), setPrice(item.price ? `${item.price}` : '')];

  const approve = async () => {
    if (!window.web3) return;
    const accounts = await window.web3.eth.getAccounts();
    // eslint-disable-next-line
    const mintContract = new window.web3.eth.Contract(mintABI.abi as any, CONTRACT_ADDRESS);

    try {
      await mintContract.methods.setApprovalForAll(MARKETPLACE_CONTRACT_ADDRESS, true).send({ from: accounts[0] });

      setIsApproved(true);
    } catch (error) {
      console.error(error);
    }

    // TODO: Undo loading
  };

  const sell = async () => {
    if (!window.web3) return;

    // TODO: Loading
    // eslint-disable-next-line
    const marketplaceContract = new window.web3.eth.Contract(marketplaceABI.abi as any, MARKETPLACE_CONTRACT_ADDRESS);

    try {
      const accounts = await window.web3.eth.getAccounts();
      await marketplaceContract.methods
        .listToken(item.id, window.web3.utils.toWei(price, 'ether'))
        .send({ from: accounts[0] });
    } catch (error) {
      console.log(error);
    }
    onClose();
    // TODO: Undo loading
  };

  const removeFromMarketplace = async () => {
    if (!window.web3) return;

    // TODO: Loading
    // eslint-disable-next-line
    const marketplaceContract = new window.web3.eth.Contract(marketplaceABI.abi as any, MARKETPLACE_CONTRACT_ADDRESS);

    try {
      const accounts = await window.web3.eth.getAccounts();
      await marketplaceContract.methods.listToken(item.id, 0).send({ from: accounts[0] });
    } catch (error) {
      console.log(error);
    }
    onClose();
    // TODO: Undo loading
  };

  return (
    <Modal onClose={onClose} onOpen={onOpen} show={show} trigger={trigger}>
      <ItemTitle>Husky #{item.id}</ItemTitle>
      {step === 0 ? (
        <>
          <ItemImage src={item.image} />
          <ModalBody>
            <span style={{ fontWeight: 700 }}>Rarity</span>:{' '}
            <span style={{ textTransform: 'capitalize' }}>{item.rarity}</span> / 27
            <br />
            <span style={{ fontWeight: 700 }}>Background</span>:{' '}
            <span style={{ textTransform: 'capitalize' }}>{item.background}</span>
            <br />
            <span style={{ fontWeight: 700 }}>Type</span>:{' '}
            <span style={{ textTransform: 'capitalize' }}>{item.type}</span>
            <div style={{ display: 'flex' }}>
              {item.price ? (
                <SellButton style={{ marginRight: '15px' }} onClick={removeFromMarketplace}>
                  Remove from marketplace
                </SellButton>
              ) : null}
              <SellButton onClick={() => (isApproved ? setStep(1) : approve())}>
                {item.price ? 'Change sell price' : isApproved ? 'Sell' : 'Approve'}
              </SellButton>
            </div>
          </ModalBody>
        </>
      ) : (
        <ModalBody>
          <Column>
            <label style={{ fontSize: '18px' }} htmlFor="price">
              Set your price
            </label>
            <div>
              <SellInput
                id="price"
                type="number"
                step="0.1"
                placeholder="Price"
                value={price || ''}
                onChange={(event) => setPrice(event.target.value)}
              />
              AVAX
            </div>
          </Column>
          <SellButton onClick={sell}>Sell</SellButton>
        </ModalBody>
      )}
    </Modal>
  );
};

export default ItemModal;
