import React, { useState } from 'react';
import styled from 'styled-components';
import { IoMdArrowDropdown } from 'react-icons/io';

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
  padding: 8px;
  min-width: 145px;
`;

const DropdownBody = styled.div`
  position: absolute;
  top: 44px;
  left: 0;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
  z-index: 20;
  overflow: hidden;
`;

const DropdownIcon = styled(IoMdArrowDropdown)`
  transition: 0.2s;
  transform: ${(props: { opened: boolean }) => (props.opened ? 'rotate(180deg)' : 'rotate(0)')};
`;

const DropdownItem = styled.div`
  padding: 5px;
  transition: 0.1s;

  &:hover {
    background-color: black;
    color: white;
  }
`;

type DropdownProps = {
  ascending: boolean;
  items: Array<'id' | 'rarity'>;
  title: string;
  onChange: React.Dispatch<React.SetStateAction<'id' | 'rarity'>>;
  setAscending: (arg0: boolean) => void;
  value: string;
  style?: Record<string, string>;
};

const Dropdown: React.FC<DropdownProps> = ({ ascending, setAscending, style, title, items, onChange, value }) => {
  const [open, setOpen] = useState(false);
  return (
    <DropdownContainer style={style} onClick={() => setOpen((prevOpen) => !prevOpen)}>
      {title}: <div style={{ width: '60px', marginLeft: '6px' }}>{value === 'rarity' ? 'Rarity' : 'ID'}</div>
      <DropdownIcon opened={open} size={20} />
      {open && (
        <DropdownBody>
          {items.map((item) => {
            return (
              <React.Fragment key={item}>
                <DropdownItem
                  style={{ fontWeight: value === item && ascending ? 700 : 400 }}
                  onClick={() => [onChange(item), setAscending && setAscending(true)]}
                >
                  {item === 'rarity' ? 'Rarity Ascending' : 'ID Ascending'}
                </DropdownItem>
                <DropdownItem
                  style={{ fontWeight: value === item && !ascending ? 700 : 400 }}
                  onClick={() => [onChange(item), setAscending && setAscending(false)]}
                >
                  {item === 'rarity' ? 'Rarity Descending' : 'ID Descending'}
                </DropdownItem>
              </React.Fragment>
            );
          })}
        </DropdownBody>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
