import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Web3 from 'web3';

import * as marketplaceABI from 'abi/marketplace.json';
import metadata from 'utils/meta.json';
import bone1 from 'assets/husky-rarity/bone-1.png';
import bone2 from 'assets/husky-rarity/bone-2.png';
import bone3 from 'assets/husky-rarity/bone-3.png';
import bone4 from 'assets/husky-rarity/bone-4.png';
import bone5 from 'assets/husky-rarity/bone-5.png';
import boneGold from 'assets/husky-rarity/bone-gold.png';
import { DropdownPrice, FilterDropdown, ImageSkeleton, Pagination } from 'components';
import { ItemModal } from './components';
import { MARKETPLACE_CONTRACT_ADDRESS } from 'utils/addresses';

// eslint-disable-next-line
declare let window: any;

const PAGE_SIZE = 9;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 80px - 50px);
`;

const MarketplaceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  width: 100%;
  padding-bottom: 30px;
  margin-top: 30px;

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 620px) {
    grid-template-columns: 1fr;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
  position: relative;
  overflow: hidden;
`;

const ItemRarity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  color: black;
  padding: 10px;
  width: 42px;
  height: 36px;
  z-index: 12;
  overflow: hidden;
`;

const ItemRarityImage = styled.img`
  height: 50px;
  width: 50px;
`;

const ItemTitle = styled.h3`
  margin: 0 0 10px 0;
`;

const ItemImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);

  &:after {
    animation: ${(props: { rarity: number }) => (props.rarity < 3 ? 'shine 5s ease-in-out infinite' : 'none')};
    animation-fill-mode: forwards;
    content: '';
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    overflow: hidden;
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @keyframes shine {
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    100% {
      opacity: 0;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
    }
  }
`;

// const ItemImage = styled.img`
//   width: 100%;
//   height: 100%;
// `;

const ItemButton = styled.button`
  padding: 8px 5px;
  border-radius: 6px;
  font-size: 18px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
  margin-top: 10px;
`;

const Insights = styled.div`
  margin-left: 15px;

  @media screen and (max-width: 1000px) {
    margin-left: 0;
    margin-top: 15px;
  }
`;

function paginate(
  array: Array<{ image: string; rarity: number; id: string; background: string; type: string; price: number }>,
  page_size: number,
  page_number: number,
): Array<{ image: string; rarity: number; id: string; background: string; type: string; price: number }> {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return [...array].slice((page_number - 1) * page_size, page_number * page_size);
}

const Marketplace: React.FC = () => {
  const [groupBy, setGroupBy] = useState<'id' | 'rarity' | 'price'>('id');
  const [ascending, setAscending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBackground, setFilterBackground] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('');
  const [items, setItems] = useState<Array<{
    image: string;
    rarity: number;
    id: string;
    background: string;
    type: string;
    price: number;
  }> | null>(null);
  const [volume, setVolume] = useState('0');

  const rarityToBone = (rarity: number) => {
    if (rarity >= 23) return bone5;
    if (rarity >= 18) return bone4;
    if (rarity >= 13) return bone3;
    if (rarity >= 8) return bone2;
    if (rarity >= 4) return bone1;
    return boneGold;
  };

  useEffect(() => {
    (async () => {
      if (items !== null) return;
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);

        // TODO: Loading
        const marketplaceContract = new window.web3.eth.Contract(
          // eslint-disable-next-line
          marketplaceABI.abi as any,
          MARKETPLACE_CONTRACT_ADDRESS,
        );
        const result = [] as Array<{
          image: string;
          rarity: number;
          id: string;
          background: string;
          type: string;
          price: number;
        }>;

        try {
          const nbMarketplaceItems = await marketplaceContract.methods.getToSellLength().call();
          const marketplaceItems = (await marketplaceContract.methods
            .getToSell(1, nbMarketplaceItems)
            .call()) as Array<string>;

          const marketplacePrices = (await marketplaceContract.methods
            .getToSellprices(1, nbMarketplaceItems)
            .call()) as Array<string>;

          if (marketplaceItems.length === 0) {
            setItems([]);
            return;
          }

          const vol = await marketplaceContract.methods.sellVolume().call();
          setVolume(Web3.utils.fromWei(`${vol}`, 'ether'));

          for (let i = 0; i < marketplaceItems.length; i++) {
            const meta = metadata.find((value) => value.id === `${marketplaceItems[i]}`) as {
              image: string;
              rarity: number;
              background: string;
              type: string;
              id: string;
            };
            const price = marketplacePrices[i];

            result.push({ ...meta, price: parseFloat(Web3.utils.fromWei(`${price}`, 'ether')) });
          }
          setItems([...result]);
        } catch (error) {
          console.log(error);
          setItems([]);
          setItems(null);
        }
      }
    })();
  });

  const filteredItems =
    (items || [])
      .sort((left, right): number => {
        let result = 0;
        if (groupBy === 'id') {
          if (left[groupBy] && parseInt(left[groupBy]) > parseInt(right[groupBy])) result = ascending ? 1 : -1;
          else if (parseInt(left[groupBy]) < parseInt(right[groupBy])) result = ascending ? -1 : 1;
        } else {
          if (left[groupBy] && left[groupBy] > right[groupBy]) result = ascending ? 1 : -1;
          if (left[groupBy] < right[groupBy]) result = ascending ? -1 : 1;
        }
        return groupBy === 'rarity' ? result * -1 : result;
      })
      .filter((item) => !filterBackground || item.background === filterBackground)
      .filter((item) => !filterType || item.type === filterType) || [];

  const paginatedItems = paginate(filteredItems, PAGE_SIZE, currentPage);
  const floorPrice =
    filteredItems && filteredItems.length ? Math.min(...(filteredItems?.map((item) => item.price) ?? [])) : 0;

  return (
    <Container>
      <h1 style={{ margin: '15px 0' }}>Marketplace</h1>
      <FiltersContainer>
        <DropdownPrice
          ascending={ascending}
          setAscending={setAscending}
          style={{ marginRight: '15px' }}
          title="Order by"
          items={['id', 'rarity', 'price']}
          value={groupBy}
          onChange={(value) => [setGroupBy(value), setCurrentPage(1)]}
        />
        <FilterDropdown
          style={{ marginRight: '15px' }}
          title="Background"
          items={['black', 'blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow']}
          value={filterBackground}
          onChange={(value) => [setFilterBackground(value), setCurrentPage(1)]}
        />
        <FilterDropdown
          title="Type"
          items={['food', 'mecha', 'metal', 'minerals', 'nature', 'animals', 'textile', 'arty', '']}
          value={filterType}
          onChange={(value) => [setFilterType(value), setCurrentPage(1)]}
        />
        <Insights>
          <span style={{ fontWeight: 700 }}>Floor Price: </span>
          {floorPrice} AVAX
        </Insights>
        <Insights>
          <span style={{ fontWeight: 700 }}>Volume: </span>
          {volume} AVAX
        </Insights>
      </FiltersContainer>
      <Pagination length={filteredItems?.length ?? 0} value={currentPage} onChange={setCurrentPage} />
      <MarketplaceContainer>
        {items === null ? (
          <h2 style={{ margin: 0 }}>Loading...</h2>
        ) : paginatedItems.length ? (
          paginatedItems.map((item) => {
            return (
              <ItemContainer key={item.id}>
                <ItemRarity>
                  <ItemRarityImage src={rarityToBone(item.rarity)} />
                </ItemRarity>
                <ItemImageContainer rarity={item.rarity}>
                  <ImageSkeleton src={item.image} />
                </ItemImageContainer>
                <ItemTitle>Husky #{item.id}</ItemTitle>
                Price: {item.price} AVAX
                <ItemModal item={item || []} trigger={<ItemButton>Details</ItemButton>} />
              </ItemContainer>
            );
          })
        ) : (
          <h2 style={{ margin: 0 }}>{"Oops, It's empty here"}</h2>
        )}
      </MarketplaceContainer>
    </Container>
  );
  // return <h2>Maintenance, the marketplace will be back soon...</h2>;
};

export default Marketplace;
