import React, { useState } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import About from 'about';
import Admin from 'admin';
import Marketplace from 'marketplace';
import Mint from 'mint';
import Wallet from 'wallet';
import poweredByAvalanche from 'assets/powered-by-avalanche.png';
import { Banderola, ConnectButton, Navbar } from 'components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 740px) {
    align-items: space-around;
  }
`;

const AvalancheLogo = styled.img`
  position: fixed;
  bottom: 70px;
  right: 20px;
  height: 50px;
  z-index: 20;

  @media screen and (max-width: 1535px) {
    display: none;
  }
`;

const App: React.FC = () => {
  const [connected, setConnected] = useState(false);
  const [address, setAddress] = useState('');
  const [rightChainID, setRightChainID] = useState(false);

  return (
    <Router>
      <Navbar address={address} />
      <div style={{ overflow: 'scroll' }}>
        <Container>
          <Switch>
            <Route exact path="/">
              <Mint rightChainID={rightChainID} />
            </Route>
            <Route path="/wallet">
              <Wallet connected={connected} />
            </Route>
            <Route path="/marketplace">
              <Marketplace />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/encule">
              <Admin />
            </Route>
            <Route>
              <div>Not Found</div>
            </Route>
          </Switch>
        </Container>
      </div>
      <ConnectButton
        address={address}
        setAddress={setAddress}
        connected={connected}
        setConnected={setConnected}
        rightChainID={rightChainID}
        setRightChainID={setRightChainID}
      />
      <AvalancheLogo src={poweredByAvalanche} />
      <Banderola />
    </Router>
  );
};

export default App;
