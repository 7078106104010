import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Web3 from 'web3';

import * as marketplaceABI from 'abi/marketplace.json';
import * as mintABI from 'abi/mint.json';
import metadata from 'utils/meta.json';
import bone1 from 'assets/husky-rarity/bone-1.png';
import bone2 from 'assets/husky-rarity/bone-2.png';
import bone3 from 'assets/husky-rarity/bone-3.png';
import bone4 from 'assets/husky-rarity/bone-4.png';
import bone5 from 'assets/husky-rarity/bone-5.png';
import boneGold from 'assets/husky-rarity/bone-gold.png';
import { CONTRACT_ADDRESS, MARKETPLACE_CONTRACT_ADDRESS } from 'utils/addresses';
import { Dropdown, ImageSkeleton } from 'components';
import { ItemModal } from './components';

// eslint-disable-next-line
declare let window: any;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 80px - 50px);
`;

const ClaimContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  /* overflow: hidden; */
  margin-bottom: 15px;
  height: 40px;
`;

const ClaimValue = styled.div`
  border-radius: 4px 0 0 4px;
  padding: 6px;
`;

const ClaimButton = styled.button`
  display: grid;
  place-items: center;
  font-size: 16px;
  background-color: black;
  height: 100%;
  color: white;
  padding: 6px;
  border-radius: 0 4px 4px 0;
`;

const WalletContainer = styled.div`
  // TODO: Change to grid
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  width: 100%;
  padding-bottom: 30px;
  margin-top: 30px;

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 620px) {
    grid-template-columns: 1fr;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
  position: relative;
  overflow: hidden;
`;

const ItemRarity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  color: black;
  padding: 10px;
  width: 42px;
  height: 36px;
  z-index: 12;
  overflow: hidden;
`;

const ItemRarityImage = styled.img`
  height: 50px;
  width: 50px;
`;

const ItemTitle = styled.h3`
  margin: 0;
`;

const ItemImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);

  &:after {
    animation: ${(props: { rarity: number }) => (props.rarity < 3 ? 'shine 5s ease-in-out infinite' : 'none')};
    animation-fill-mode: forwards;
    content: '';
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    overflow: hidden;
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @keyframes shine {
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    100% {
      opacity: 0;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
    }
  }
`;

const ItemButton = styled.button`
  padding: 8px 5px;
  border-radius: 6px;
  font-size: 18px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 30%) 5px 5px 15px;
`;

type WalletProps = {
  connected: boolean;
};

const Wallet: React.FC<WalletProps> = ({ connected }) => {
  const [groupBy, setGroupBy] = useState<'id' | 'rarity'>('id');
  const [ascending, setAscending] = useState(true);
  const [toClaim, setToClaim] = useState(0);
  const [items, setItems] = useState<null | Array<{
    image: string;
    rarity: number;
    id: string;
    background: string;
    type: string;
    price: number;
  }>>(null);
  const [isApproved, setIsApproved] = useState(false);

  const rarityToBone = (rarity: number) => {
    if (rarity >= 23) return bone5;
    if (rarity >= 18) return bone4;
    if (rarity >= 13) return bone3;
    if (rarity >= 8) return bone2;
    if (rarity >= 4) return bone1;
    return boneGold;
  };

  const claim = async () => {
    if (!window.web3) return;

    // TODO: Loading
    // eslint-disable-next-line
    const marketplaceContract = new window.web3.eth.Contract(marketplaceABI.abi as any, MARKETPLACE_CONTRACT_ADDRESS);

    try {
      const accounts = await window.web3.eth.getAccounts();
      await marketplaceContract.methods.claimRewards().send({ from: accounts[0] });
    } catch (error) {
      console.log(error);
    }
    // TODO: Undo loading
  };

  useEffect(() => {
    (async () => {
      if (items !== null) return;
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);

        // TODO: Loading
        try {
          const accounts = await window.web3.eth.getAccounts();
          // eslint-disable-next-line
          const mintContract = new window.web3.eth.Contract(mintABI.abi as any, CONTRACT_ADDRESS);
          const marketplaceContract = new window.web3.eth.Contract(
            // eslint-disable-next-line
            marketplaceABI.abi as any,
            MARKETPLACE_CONTRACT_ADDRESS,
          );

          const approved = await mintContract.methods
            .isApprovedForAll(accounts[0], MARKETPLACE_CONTRACT_ADDRESS)
            .call();
          setIsApproved(approved);

          const claimValue = await marketplaceContract.methods.getTotalClaim(accounts[0]).call();
          setToClaim(claimValue);

          const nfts = await mintContract.methods.tokensOfOwner(accounts[0]).call();
          const result = [] as Array<{
            image: string;
            rarity: number;
            id: string;
            background: string;
            type: string;
            price: number;
          }>;

          const nbMarketplaceItems = await marketplaceContract.methods.getToSellLength().call();
          const marketplaceItems = (await marketplaceContract.methods
            .getToSell(1, nbMarketplaceItems)
            .call()) as Array<string>;

          const marketplacePrices = (await marketplaceContract.methods
            .getToSellprices(1, nbMarketplaceItems)
            .call()) as Array<string>;

          for (let i = 0; i < nfts.length; i++) {
            const meta = metadata.find((value) => value.id === `${nfts[i]}`) as {
              image: string;
              rarity: number;
              background: string;
              type: string;
              id: string;
            };

            const indexOfItem = marketplaceItems.indexOf(nfts[i]);
            const price = indexOfItem === -1 ? 0 : marketplacePrices[indexOfItem];
            result.push({ ...meta, price: parseFloat(Web3.utils.fromWei(`${price}`, 'ether')) });
          }

          setItems([...result]);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  });

  return (
    <Container>
      {connected ? (
        <>
          <h1 style={{ margin: '15px 0' }}>Wallet</h1>
          <ClaimContainer>
            <ClaimValue>{Web3.utils.fromWei(`${toClaim}`, 'ether')} AVAX</ClaimValue>
            <ClaimButton onClick={claim}>Claim</ClaimButton>
          </ClaimContainer>
          {(items || []).length ? (
            <>
              <FiltersContainer>
                <Dropdown
                  ascending={ascending}
                  setAscending={setAscending}
                  title="Order by"
                  items={['rarity', 'id']}
                  value={groupBy}
                  onChange={setGroupBy}
                />
              </FiltersContainer>
              <WalletContainer>
                {(items || [])
                  .sort((left, right): number => {
                    let result = 0;
                    if (groupBy === 'id') {
                      if (parseInt(left[groupBy]) > parseInt(right[groupBy])) result = ascending ? 1 : -1;
                      if (parseInt(left[groupBy]) < parseInt(right[groupBy])) result = ascending ? -1 : 1;
                    } else {
                      if (left[groupBy] > right[groupBy]) result = ascending ? 1 : -1;
                      if (left[groupBy] < right[groupBy]) result = ascending ? -1 : 1;
                    }
                    return groupBy === 'rarity' ? result * -1 : result;
                  })
                  .map((item) => {
                    return (
                      <ItemContainer key={item.id}>
                        <ItemRarity>
                          <ItemRarityImage src={rarityToBone(item.rarity)} />
                        </ItemRarity>
                        <ItemImageContainer rarity={item.rarity}>
                          <ImageSkeleton src={item.image} />
                        </ItemImageContainer>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                            height: '30px',
                          }}
                        >
                          <ItemTitle>Husky #{item.id}</ItemTitle>
                          {item.price ? <div style={{ border: '2px solid black', padding: '4px' }}>ON SALE</div> : null}
                        </div>
                        <ItemModal
                          setIsApproved={setIsApproved}
                          isApproved={isApproved}
                          item={item}
                          trigger={<ItemButton>Details</ItemButton>}
                        />
                      </ItemContainer>
                    );
                  })}
              </WalletContainer>
            </>
          ) : items === null ? (
            <h2 style={{ margin: 0 }}>Loading...</h2>
          ) : (
            <h2 style={{ margin: 0 }}>{"Oops, It's empty here"}</h2>
          )}
        </>
      ) : (
        <h1 style={{ margin: '15px 100px 15px 0' }}>Please, connect using Metamask</h1>
      )}
    </Container>
  );
};

export default Wallet;
