import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink as NLink, Link } from 'react-router-dom';
import { AiOutlineClose, GoThreeBars } from 'react-icons/all';

import huskyLogo from 'assets/husky-logo.png';

const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 15px;
`;

const Nav = styled.nav`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  max-width: 1260px;
`;

const NavList = styled.ul`
  display: flex;
  align-items: center;
  @media screen and (max-width: 440px) {
    display: none;
  }
`;
const NavItem = styled.li`
  list-style: none;
  margin-left: 30px;
  color: white;
`;

const NavLogo = styled.img`
  height: 55px;
  width: 55px;
  /* margin-right: 30px; */
`;

const NavLink = styled(NLink)`
  border-bottom: 2px solid transparent;
  border-radius: 2px;
  transition: 0.2s;
  padding: 4px 0;

  &:hover {
    border-color: #ffffff99;
  }
`;

const MobileIcon = styled(GoThreeBars)`
  display: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  width: 30px;
  color: white;

  @media screen and (max-width: 440px) {
    display: block;
  }
`;

const MobileMenuContainer = styled.ul`
  display: none;
  height: ${(props: { opened: boolean }) => (props.opened ? 'calc(100vh - 80px - 50px)' : '0')};
  padding: ${(props: { opened: boolean }) => (props.opened ? '30px 0' : '0')};
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  background-color: black;
  transition: height 0.3s;
  z-index: 20;

  @media screen and (max-width: 440px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* play: ${(props: { opened: boolean }) => (props.opened ? 'block' : 'none')}; */
  }
`;

const CloseIcon = styled(AiOutlineClose)`
  display: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  width: 30px;
  color: white;

  @media screen and (max-width: 440px) {
    display: block;
  }
`;

const MobileMenuItem = styled.li`
  transition: height 0.4s;
  height: 30px;
  /* margin-top: 20px; */
  overflow: hidden;
  display: none;
  font-size: 22px;

  @media screen and (max-width: 440px) {
    display: flex;
    justify-content: center;
    height: ${(props: { opened: boolean }) => (props.opened ? '30px' : '0')};
    width: 100%;
  }
`;

type MobileNavbarProps = {
  opened: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const MobileNavbar: React.FC<MobileNavbarProps> = ({ opened, onOpen, onClose }) => {
  return (
    <>
      {opened ? <CloseIcon onClick={onClose} /> : <MobileIcon onClick={onOpen} />}
      <MobileMenuContainer opened={opened}>
        <MobileMenuItem opened={opened}>
          <Link onClick={onClose} style={{ color: 'white' }} to="/">
            Mint
          </Link>
        </MobileMenuItem>
        <MobileMenuItem opened={opened}>
          <Link onClick={onClose} style={{ color: 'white' }} to="/wallet">
            Wallet
          </Link>
        </MobileMenuItem>
        <MobileMenuItem opened={opened}>
          <Link onClick={onClose} style={{ color: 'white' }} to="/about">
            About
          </Link>
        </MobileMenuItem>
        <MobileMenuItem opened={opened}>
          <Link onClick={onClose} style={{ color: 'white' }} to="/marketplace">
            Marketplace
          </Link>
        </MobileMenuItem>
      </MobileMenuContainer>
    </>
  );
};

type NavbarProps = {
  address: string;
};

const Navbar: React.FC<NavbarProps> = ({ address }) => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  const onOpen = () => setMobileMenuOpened(true);

  const onClose = () => setMobileMenuOpened(false);

  return (
    <NavContainer>
      <Nav>
        <Link to="/">
          <NavLogo src={huskyLogo} />
        </Link>
        <MobileNavbar opened={mobileMenuOpened} onOpen={onOpen} onClose={onClose} />
        <NavList>
          <NavItem>
            <NavLink exact to="/" activeStyle={{ borderColor: 'white' }}>
              Mint
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/wallet" activeStyle={{ borderColor: 'white' }}>
              Wallet
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/about" activeStyle={{ borderColor: 'white' }}>
              About
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/marketplace" activeStyle={{ borderColor: 'white' }}>
              Marketplace
            </NavLink>
          </NavItem>
          {address === '0x2044fB0BeD650B3771b7af0BB56dbf0A6f337b78' ? (
            <NavItem>
              <NavLink to="/encule" activeStyle={{ borderColor: 'white' }}>
                Enculé
              </NavLink>
            </NavItem>
          ) : null}
        </NavList>
      </Nav>
    </NavContainer>
  );
};

export default Navbar;
