import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaTelegramPlane, FaDiscord } from 'react-icons/fa';

const BanderolaContainer = styled.div`
  position: fixed;
  z-index: 4;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: rgba(136, 165, 191, 0.48) -6px -2px 16px 0px, rgba(255, 255, 255, 0.8) 6px 2px 16px 0px; */
  box-shadow: rgb(0 0 0 / 25%) 0px -5px 15px;
`;

const Banderola: React.FC = () => {
  return (
    <BanderolaContainer>
      <a style={{ margin: '0 8px' }} href="https://twitter.com/AvaxHusky">
        <FaTwitter size={22} />
      </a>
      <a style={{ margin: '0 8px' }} href="https://t.me/HuskyAvalanche">
        <FaTelegramPlane size={24} />
      </a>
      <a
        style={{
          margin: '0 8px',
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: '80px',
          width: '28px',
          height: '28px',
        }}
        href="https://discord.gg/VHv8GHmhSt"
      >
        <FaDiscord style={{ position: 'relative', top: '-6px', left: '-6px' }} color="black" size={40} />
      </a>
    </BanderolaContainer>
  );
};

export default Banderola;
