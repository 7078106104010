import React, { useRef } from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #000000dd;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  z-index: 1001;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  min-height: '450px';
  /* height: auto; */
  width: 550px;
  overflow: hidden;

  animation: modalAppear 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  @keyframes modalAppear {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const CloseIcon = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 2em;
  height: 2em;
  z-index: 10;
  color: lightgray;
  transition: 0.1s;

  &:hover {
    color: #000000aa;
  }
`;

type ModalProps = {
  className?: string;
  onClose: () => void;
  onOpen?: () => void;
  show: boolean;
  trigger?: JSX.Element;
};

const Modal: React.FC<ModalProps> = ({ children, className, onClose, onOpen, show, trigger }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const onBackgroundClick = (e: React.MouseEvent) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  return (
    <>
      {trigger && <div onClick={(event) => [event.stopPropagation(), onOpen && onOpen()]}>{trigger}</div>}
      {show &&
        ReactDom.createPortal(
          <Background ref={modalRef} onClick={onBackgroundClick}>
            <ModalContainer className={className} onClick={(event) => event.stopPropagation()}>
              {children}
              <CloseIcon onClick={onClose} />
            </ModalContainer>
          </Background>,
          document.getElementById('modal-root') as HTMLElement,
        )}
    </>
  );
};

export default Modal;
