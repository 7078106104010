import React from 'react';
import styled from 'styled-components';

import husky1 from 'assets/husky-nfts/ABSHUSK.png';
import husky2 from 'assets/husky-nfts/ABSTRACTWALLHUSK.png';
import husky3 from 'assets/husky-nfts/ACRYLICHUSK.png';
import husky4 from 'assets/husky-nfts/METALHUSK.png';
import husky5 from 'assets/husky-nfts/SCIFIPANEL11V2HUSK.png';
import husky6 from 'assets/husky-nfts/FABPAT2HUSK.png';
import husky7 from 'assets/husky-nfts/FLATHUSK.png';

const Panel = styled.div`
  height: calc(100vh - 80px - 50px);
  overflow: hidden;
  padding: 0 54px;
  width: calc(50% + 108px);

  @media screen and (max-width: 740px) {
    height: 300px;
    width: calc(100% + 50px);
    margin-left: -25px;
    padding: 50px 0;
    margin-top: -50px;
  }
`;

const Marquee = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: marquee 10s linear infinite;
  @media screen and (max-width: 740px) {
    animation: marquee-mobile 12s linear infinite;
    flex-direction: row;
    justify-content: flex-start;
  }

  @keyframes marquee-mobile {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-2450px, 0, 0);
    }
  }

  @keyframes marquee {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -50%, 0);
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  font-size: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
  @media screen and (max-width: 740px) {
    display: flex;
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.25) 54px 0px 55px, rgba(0, 0, 0, 0.12) -12px 0px 30px, rgba(0, 0, 0, 0.12) 4px 0px 6px,
      rgba(0, 0, 0, 0.17) 12px 0px 13px, rgba(0, 0, 0, 0.09) -3px 0px 5px;
  }
`;

const Image = styled.img`
  width: 100%;
  @media screen and (max-width: 740px) {
    width: 350px;
  }
`;

const Banner: React.FC = () => {
  // TODO: Box shadow
  return (
    <Panel>
      <Marquee>
        <ImageContainer>
          <Image src={husky1} />
          <Image src={husky2} />
          <Image src={husky3} />
          <Image src={husky4} />
          <Image src={husky5} />
          <Image src={husky6} />
          <Image src={husky7} />
        </ImageContainer>
        <ImageContainer>
          <Image src={husky1} />
          <Image src={husky2} />
          <Image src={husky3} />
          <Image src={husky4} />
          <Image src={husky5} />
          <Image src={husky6} />
          <Image src={husky7} />
        </ImageContainer>
      </Marquee>
    </Panel>
  );
};

export default Banner;
