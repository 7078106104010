export const CHAIN_ID = 0xa86a;
export const CHAIN_ID_STRING = '0xa86a';
export const CHAIN_NAME = 'Avalanche Network';
export const RPC_URL = 'https://api.avax.network/ext/bc/C/rpc';
export const CONTRACT_ADDRESS = '0xf0229b39A278ce003F852b1965Fdb624602496CA';
export const MARKETPLACE_CONTRACT_ADDRESS = '0x8b0a12b3108a0fACC17c71091EF6013aF76dCCE3';
export const MINT_PRICE = 2.5;
export const BASE_URI = 'https://ayobo.mypinata.cloud/ipfs/QmQPWdR5XGsYMTuuZTMLWWLzecQpCvm9XcfWGT2PoADiSw/';

// export const CHAIN_ID = 0xa869;
// export const CHAIN_ID_STRING = '0xa869';
// export const CHAIN_NAME = 'Avalanche Fuji Network';
// export const RPC_URL = 'https://api.avax-test.network/ext/bc/C/rpc';
// export const CONTRACT_ADDRESS = '0xeD65C84B9a3480B2cFe761946e0B57B2616e56b9';
// export const MARKETPLACE_CONTRACT_ADDRESS = '0x85098042fE3BFc54B6E69e1cb7C391b8bf9856FA';
